.text_input {
  @apply bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-sky-300 transition-colors
}

.number_input {
  @apply bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2.5 outline-sky-300 transition-colors w-1/2
}

.cash_input {
  @apply bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2.5 pl-10 outline-sky-300 transition-colors w-1/2
}

.percent_input {
  @apply bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2.5 pl-10 outline-sky-300 transition-colors w-1/3
}

.radio_input {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 transition-colors
}

.radio_label {
  @apply w-full py-4 ml-3 text-base font-normal mb-0
}

.label {
  @apply font-normal text-lg text-gray-700
}

.label_sm {
  @apply font-normal text-base text-gray-700 pt-2
}

.label_xs {
  @apply font-normal text-base text-gray-700
}

.grid_label {
  @apply font-normal text-base text-gray-700
}

.grid_cash {
  @apply bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2.5 pl-10 outline-sky-300 transition-colors w-full
}

.grid_head {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 1fr;
  gap: 10px;
  height: 50px;
  align-items: first baseline;
}

.flex_container1 {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: first baseline;
  margin-bottom: 10px;
}

.flex_container_suggestion {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: center;
}

.flex_child1 {
  flex: 0.18;
}

.flex_child2 {
  flex: 1.5;
}

.flex_child3 {
  flex: 1;
}

.grid2 {
  display: grid;
  grid-template-columns: 1.2fr 0.6fr 0.8fr;
  gap: 10px;
  margin-bottom: 10px;
  align-items: first baseline;
}

.lower_height {
  height: 4rem;
}

.align_end {
  align-items: end;
}

.padl {
  padding-left: 1.8rem;
}

.padl12 {
  padding-left: 3rem;
}

.register_button {
  @apply flex mb-3 items-center justify-center px-5 p-3 border rounded-xl border-gray-300 transition-all duration-300 hover:-translate-y-1 hover:shadow-lg
}

.loading_box {
  position: fixed;
  top: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  padding-left: 15px;
  padding-right: 12px;
  background-color: #ffffff;
  color: black;
  border-radius: 16px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.086);
  font-size: 14px;
  z-index: 9999;
  border: solid 1px #cacaca;
  line-height: 1.5;
  width: 80%;
}

@media screen and (min-width: 600px) {
  .loading_box {
    width: auto; /* on screens bigger than 600px, let the box size be determined by its content */
    height: auto; /* on screens bigger than 600px, let the box size be determined by its content */
  }
}